import React from 'react';
import usePortal from 'react-useportal';
import DrawerContainer from '../components/Common/DrawerContainer';

export default function useDrawer() {
  const { openPortal, closePortal, isOpen, Portal } = usePortal();

  const Drawer = props => {
    return (
      <Portal>
        <DrawerContainer {...props} closeDrawer={closePortal}>
          {props.children}
        </DrawerContainer>
      </Portal>
    );
  };

  return {
    closeDrawer: closePortal,
    Drawer,
    isOpen,
    openDrawer: openPortal
  };
}
