import React, { FC, useState } from 'react';
import FilterHeading from '../Filter/FilterHeading';
import FilterBody from '../Filter/FilterBody';
import FilterTitledSection from '../Filter/FilterTitledSection';
import FilterItemContainer from '../Filter/FilterItemContainer';
import PerkItem from '../PerksPage/PerkItem';
import { SanityPerk } from '../../../graphql-types';
import {
  MultipleSelect,
  useFilter,
  sortByProperty,
  Box,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';

const categoryOrder = [
  { name: 'Mind & Body', order: 1 },
  { name: 'Wellness', order: 2 },
  { name: 'Fitness & Yoga', order: 3 },
  { name: 'Healthy Eating', order: 4 }
];

const mapToOrderedCategory = (c: Category) => {
  const category = categoryOrder.find(i => i.name === c.name);
  if (category) return { ...c, order: category.order };
  return c;
};

interface Category {
  name: string;
  items: SanityPerk[];
  id?: string;
  order?: number;
}

interface PerksFilterProps {
  items: SanityPerk[];
  onPerkClick: Function;
  typeFilterOptions: any;
  locationFilterOptions: any;
}

const PerksFilter: FC<PerksFilterProps> = ({
  items,
  onPerkClick,
  typeFilterOptions,
  locationFilterOptions
}) => {
  const [activeTypeFilter, setActiveTypeFilter] = useState([]);
  const [activeLocationFilter, setActiveLocationFilter] = useState([]);
  const perks = useFilter<SanityPerk>(
    items,
    [activeTypeFilter, activeLocationFilter],
    [
      (perk: SanityPerk) =>
        !activeTypeFilter.length ||
        activeTypeFilter.some(item => item.label === perk.category[0].name),
      (perk: SanityPerk) =>
        !activeLocationFilter.length ||
        activeLocationFilter.some(item => {
          const { value } = item;
          return perk.centers.some(center => value.includes(center.name));
        })
    ]
  );

  return (
    <>
      <FilterHeading title="All Member Perks">
        <Box
          mr={getResponsiveProp('none', 's')}
          mb={getResponsiveProp('s', 'none')}
        >
          <MultipleSelect
            name="Type"
            options={typeFilterOptions}
            onSelect={setActiveTypeFilter}
            alignOptionsRight
            alignOptionsRightOnMobile={false}
          />
        </Box>
        <Box>
          <MultipleSelect
            name="Location"
            options={locationFilterOptions}
            onSelect={setActiveLocationFilter}
            alignOptionsRight
            alignOptionsRightOnMobile={false}
          />
        </Box>
      </FilterHeading>
      <FilterBody>
        {sortByProperty(perks, (p: SanityPerk) => p.category[0].name)
          // Adds an "order" propery to the category
          .map(mapToOrderedCategory)

          // Sorts categories based on "order prop"
          .sort((a: Category, b: Category) => a.order - b.order)

          // Renders categories
          .map((category: Category) => (
            <FilterTitledSection title={category.name} key={category.id}>
              {category.items.map(perk => (
                <FilterItemContainer onClick={onPerkClick(perk)}>
                  <PerkItem
                    logo={perk.logo}
                    description={perk.description}
                    perkName={perk.title}
                  />
                </FilterItemContainer>
              ))}
            </FilterTitledSection>
          ))}
      </FilterBody>
    </>
  );
};

export default PerksFilter;
