import React from 'react';
import { Box, WebsiteWrapper } from '@parsleyhealth/cilantro-ui';

interface FilterBodyProps {
  children: React.ReactNode;
}

export default function FilterBody({ children }: FilterBodyProps) {
  return (
    <Box>
      <WebsiteWrapper>{children}</WebsiteWrapper>
    </Box>
  );
}
