import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { Hero, Button } from '@parsleyhealth/cilantro-ui';
import BlockContent from '../BlockContent';
import { SanityBlock, SanityPerksPageHeading } from '../../../graphql-types';
import { processImage } from '../page-builder/procesors';

interface PerksHeadingProps extends SanityPerksPageHeading {
  description?: SanityBlock[];
  title?: string;
  ctaButton?: any;
  image?: any;
}

const PerksHeading: FC<PerksHeadingProps> = ({
  title,
  ctaButton,
  image,
  description
}) => {
  const { text, url } = ctaButton;
  return (
    <Hero
      buttons={<Button onClick={() => navigate(`/${url}`)}>{text}</Button>}
      description={<BlockContent blocks={description} pStyle="pl" />}
      heading={title}
      image={processImage(image)}
      split
      boxedText={false}
    />
  );
};

export default PerksHeading;
