import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from '@parsleyhealth/cilantro-ui';
import PerksPageHeading from '../components/PerksPage/PerksHeading';
import PerksPageFilter from '../components/PerksPage/PerksFilter';
import {
  SanityPerksPage,
  SanityPerk,
  SanityCenter,
  SanityPerkCategory
} from '../../graphql-types';
import { SEO } from '../components/SEO';
import { flattenQueryCollection } from '../lib/helpers';
import useDrawer from '../lib/useDrawer';
import PerkDrawer from '../components/PerksPage/PerkDrawer';
import { Layout } from '../redesign';

interface PerksPageProps {
  data: {
    sanityPerksPage: SanityPerksPage;
    allSanityPerk: { edges: Array<{ node: SanityPerk }> };
    allSanityCenter: { edges: Array<{ node: SanityCenter }> };
    allSanityPerkCategory: { edges: Array<{ node: SanityPerkCategory }> };
  };
}

const locationOptions = [
  { label: 'New York', stringFragment: 'NY' },
  { label: 'Los Angeles', stringFragment: 'LA' },
  { label: 'San Francisco', stringFragment: 'SF' },
  { label: 'Online', stringFragment: 'Virtual Center' }
];

const getLocationWithValues = (locations: { node: SanityCenter }[]) => {
  return locationOptions.map(l => {
    return {
      label: l.label,
      value: locations
        .filter(i => i.node.name.includes(l.stringFragment))
        .map(i => i.node.name)
    };
  });
};

const PerksPage: React.FC<PerksPageProps> = ({
  data: {
    sanityPerksPage,
    allSanityPerk,
    allSanityCenter,
    allSanityPerkCategory
  }
}) => {
  const [selectedPerk, setSelectedPerk] = useState(null);
  const { closeDrawer, openDrawer, Drawer, isOpen } = useDrawer();
  const locationFilterOptions = getLocationWithValues(allSanityCenter.edges);
  const typeFilterOptions = allSanityPerkCategory.edges.map(({ node }) => ({
    label: node.name,
    value: node.name
  }));
  const { title, heading, metaDescription, _rawHeading } = sanityPerksPage;
  const headingProps = { ...heading, description: _rawHeading.text };

  const handlePerkClick = (perk: SanityPerk) => (e: MouseEvent) => {
    setSelectedPerk(perk);
    openDrawer(e);
  };

  return (
    <ThemeProvider>
      <SEO pageTitle={title} pageDescription={metaDescription} />
      <Layout>
        <PerksPageHeading {...headingProps} />
        <PerksPageFilter
          onPerkClick={handlePerkClick}
          items={flattenQueryCollection<SanityPerk>(allSanityPerk)}
          locationFilterOptions={locationFilterOptions}
          typeFilterOptions={typeFilterOptions}
        />
        {selectedPerk && isOpen && (
          <Drawer>
            <PerkDrawer onClose={closeDrawer} perk={selectedPerk} />
          </Drawer>
        )}
      </Layout>
    </ThemeProvider>
  );
};

export const query = graphql`
  query PerksPageQuery {
    sanityPerksPage {
      metaDescription
      heading {
        ctaButton {
          url
          text
        }
        image {
          asset {
            path
            fluid {
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
              base64
              aspectRatio
            }
          }
        }
        title
      }
      _rawHeading
    }
    allSanityPerk {
      edges {
        node {
          category {
            id
            name
          }
          centers {
            name
          }
          id
          logo {
            asset {
              url
              fluid {
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
                base64
                aspectRatio
              }
            }
          }
          title
          websiteUrl
          description: _rawDescription
          offerSummary
        }
      }
    }
    allSanityCenter {
      edges {
        node {
          name
        }
      }
    }
    allSanityPerkCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`;

export default PerksPage;
