import React, { FC } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { Text, Button } from '@parsleyhealth/cilantro-ui';
import BlockContent from '../BlockContent';
import { Box } from '@parsleyhealth/cilantro-ui';
import CloseButton from '../../components/Buttons/CloseButton';

interface PerkDrawerProps {
  onClose: any;
  perk: any;
}

const PerkDrawer: FC<PerkDrawerProps> = ({ onClose, perk }) => {
  return (
    <Box>
      <CloseButton onClick={onClose} />
      <Box>
        <Box>
          <Img fluid={perk.logo.asset.fluid as FluidObject} alt="" />
        </Box>
        <Text>{perk.title}</Text>
        {perk.description && (
          <Text>
            <BlockContent blocks={perk.description} />
          </Text>
        )}
      </Box>
      {perk.offerSummary && (
        <Box>
          <Text>Perk Details</Text>
          <Text>{perk.offerSummary}</Text>
        </Box>
      )}
      <Box>
        <Button variant="primary" href="https://my.parsleyhealth.com/" mb="s">
          Sign in to Redeem Perks
        </Button>
        <Button variant="bordered" href="/membership">
          Become a Member
        </Button>
      </Box>
    </Box>
  );
};

export default PerkDrawer;
