import React, { FC, useRef, useEffect } from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

const [sm] = theme.breakpoints;

interface DrawerContainerProps {
  closeDrawer: Function;
  fullWidth?: boolean;
  children?: any;
}

const DrawerContainer: FC<DrawerContainerProps> = ({
  children,
  closeDrawer,
  fullWidth
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        closeDrawer(e);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Box
      bg="white"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      zIndex={99999}
    >
      <DrawerBox ref={wrapperRef} fullWidth={fullWidth}>
        <Box>{children}</Box>
      </DrawerBox>
    </Box>
  );
};

const DrawerBox = styled(({ fullWidth, ...rest }) => <Box {...rest} />)<{
  fullWidth: boolean;
}>`
  background-color: white;
  width: 100%;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;

  @media screen and (min-width: ${sm}) {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  }
`;

export default DrawerContainer;
