import React, { FC } from 'react';
import { Box, H3 } from '@parsleyhealth/cilantro-ui';

interface FilterTitledSectionProps {
  title: string;
  children?: any;
}

const FilterTitledSection: FC<FilterTitledSectionProps> = ({
  children,
  title
}) => {
  return (
    <Box mt="m" mb="l">
      <H3 color="text">{title}</H3>
      <Box display="flex" flexWrap="wrap" gap={['0', '0', '2%', '3%']}>
        {children}
      </Box>
    </Box>
  );
};

export default FilterTitledSection;
