import Img, { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import { SanityBasicImage } from '../../../graphql-types';
import BlockContent from '../BlockContent';
import truncateTextBlocks from '../../lib/truncate-text-blocks';

/**
 * Types
 */
export enum PerksEnum {
  FOUR_SIGMATIC = 'FOUR_SIGMATIC',
  SAKARA_LIFE = 'SAKARA_LIFE',
  TRUE_BOTANICALS = 'TRUE_BOTANICALS'
}

interface PerkItemProps {
  description: any[];
  logo: SanityBasicImage;
  perkName?: string;
}

/**
 * Component definition
 * Presents a single Perk item
 */
const PerkItem: React.FC<PerkItemProps> = ({ description, logo, perkName }) => {
  const toggleShowFullDescription = () =>
    setShowFullDescription(!showFullDescription);
  const [showFullDescription, setShowFullDescription] = useState<boolean>(
    false
  );
  return (
    <Container mt="m">
      <ImageContainer>
        <Img fluid={logo.asset.fluid as FluidObject} alt="" />
      </ImageContainer>
      <Title className="title">{perkName}</Title>
      <Description onClick={toggleShowFullDescription}>
        <BlockContent
          blocks={
            showFullDescription
              ? description
              : truncateTextBlocks(description, 110)
          }
        />
      </Description>
    </Container>
  );
};

/**
 * Styled Components
 */

const Title = styled.p`
  font-size: 20px;
  color: #4f4f4f;
  transition: 0.3s ease-out;
  margin: 3px 0 2px;
  line-height: 1.3;
  font-weight: 400;
`;

const Container = styled(Box)`
  text-align: center;
  cursor: pointer;
  &:hover > .title {
    color: #a1bcc9;
  }
`;

const ImageContainer = styled.div`
  max-width: 100px;
  height: 120px;
  margin: 0 auto 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    flex-grow: 1;
  }
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: rgb(128, 128, 128);
`;

export default PerkItem;
