import React, { FC } from 'react';
import {
  Box,
  WebsiteWrapper,
  H2,
  Text,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';

interface FilterHeadingProps {
  title: string;
  children?: any;
}

const FilterHeading: FC<FilterHeadingProps> = ({ title, children }) => {
  return (
    <Box px="xs" boxShadow="rgb(128 128 128 / 20%) 0px 4px 24px 0px">
      <WebsiteWrapper>
        <Box
          alignItems={getResponsiveProp('left', 'center')}
          display="flex"
          flexDirection={getResponsiveProp('column', 'row')}
          justifyContent="space-between"
          py="s"
        >
          <H2 mb={getResponsiveProp('s', 'none')}>{title}</H2>
          <Box
            alignItems={getResponsiveProp('left', 'center')}
            display="flex"
            flexDirection={getResponsiveProp('column', 'row')}
          >
            <Text variant="pSmall" mr="xs" mb={getResponsiveProp('s', 'none')}>
              FILTER BY
            </Text>
            {children}
          </Box>
        </Box>
      </WebsiteWrapper>
    </Box>
  );
};

export default FilterHeading;
