import styled from '@emotion/styled';

const CloseButton = styled.div`
  width: 35px;
  height: 35px;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 31px;
    height: 1px;
    background-color: black;
    border-radius: 2px;
    top: 16px;
  }

  &::before {
    transform: rotate(45deg);
    left: 2px;
  }

  &::after {
    transform: rotate(-45deg);
    right: 2px;
  }
`;

export default CloseButton;
