import React from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';

interface FilterItemContainerProps {
  children?: any;
  onClick?: Function;
}

export default function FilterItemContainer({
  children
}: FilterItemContainerProps) {
  return <Box width={['100%', '100%', '48%', '22%']}>{children}</Box>;
}
